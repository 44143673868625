import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  en: {
    welcome: 'welcome',
    paragrafo1:
      "Your one-stop supplier for high-quality building materials, precision equipment and workwear.",

    paragrafo3Bis: {
      riquadro1: {
        titolo: 'Wide Selection of Products',
        descrizione:
          'Bermar offers a wide range of items, from building materials to precision tools, ensuring the availability of everything you need in one place.',
      },
      riquadro3: {
        titolo: 'Guaranteed Quality',
        descrizione:
          'Every product available at Bermar is carefully selected to ensure the highest standards of quality and durability.',
      },
      riquadro2: {
        titolo: 'Competitive Prices',
        descrizione:
          'We offer the best prices on the market, ensuring our customers get the best value for their money without compromising on quality.',
      },
      riquadro4: {
        titolo: 'Excellent Customer Service',
        descrizione:
          'Our team is always ready to assist you, providing professional support and advice to find the best solutions for your needs.',
      },
    },
    paragrafo4: {
      titolo: 'Quality Equipment',
      descrizione:
        'At Bermar, you will find a wide range of equipment for every type of job. From hand tools to power tools, every item is chosen to offer excellent performance and reliability.',
    },

    paragrafo4Bis: {
      titolo: 'Building Materials',
      descrizione:
        'We offer a comprehensive selection of building materials. We are the ideal partner for professionals and DIY enthusiasts, always guaranteeing the highest quality.',
    },

    paragrafo5: {
      titolo: 'Workwear',
      descrizione:
        'Safety and comfort at work are essential. At Bermar, you will find durable and comfortable workwear suitable for all working conditions.',
    },
    paragrafo6: {
      titolo: 'Contact Us Today',
      descrizione:
        'Your reliable choice for buying and selling quality products. We are here to help you find exactly what you need. Do not hesitate to contact us for any information or to learn more about our wide range of products. Your satisfaction is our priority.',
    },
    contact: 'Contact Us',
  },
  it: {
    welcome: 'welcome',
    paragrafo1:
      "Il tuo unico fornitore per materiali da costruzione, attrezzature di precisione e abbigliamento da lavoro di alta qualità.",

    paragrafo3Bis: {
      riquadro1: {
        titolo: 'Ampia Selezione di Prodotti',
        descrizione:
          'Bermar offre una vasta gamma di articoli, dai materiali da costruzione agli strumenti di precisione, garantendo la disponibilità di tutto ciò che ti serve in un unico posto.',
      },
      riquadro3: {
        titolo: 'Qualità Garantita',
        descrizione:
          'Ogni prodotto disponibile da Bermar è selezionato attentamente per assicurare i più alti standard di qualità e durata nel tempo.',
      },
      riquadro2: {
        titolo: 'Prezzi Competitivi',
        descrizione:
          'Offriamo i migliori prezzi sul mercato, garantendo che i nostri clienti ottengano il massimo valore per il loro denaro senza compromettere la qualità.',
      },
      riquadro4: {
        titolo: 'Assistenza Clienti Eccellente',
        descrizione:
          'Il nostro team è sempre pronto ad aiutarti, fornendo supporto professionale e consulenza per trovare le soluzioni più adatte alle tue esigenze.',
      },
    },
    paragrafo4: {
      titolo: 'Attrezzatura di Qualità',
      descrizione:
        'Presso Bermar, troverai una vasta gamma di attrezzature per ogni tipo di lavoro. Dai utensili manuali agli strumenti elettrici, ogni articolo è scelto per offrire prestazioni eccellenti e affidabilità.',
    },

    paragrafo4Bis: {
      titolo: 'Materiali da Costruzione',
      descrizione:
        'Offriamo una selezione completa di materiali da costruzione. Siamo il partner ideale per professionisti e appassionati del fai-da-te, garantendo sempre la massima qualità.',
    },

    paragrafo5: {
      titolo: 'Vestiario da Lavoro',
      descrizione:
        'La sicurezza e il comfort sul lavoro sono essenziali. Da Bermar, trovi abbigliamento da lavoro resistente e confortevole, adatto a tutte le condizioni lavorative.',
    },
    paragrafo6: {
      titolo: 'Contattaci Oggi Stesso',
      descrizione:
        'La tua scelta affidabile per l\'acquisto e la vendita di prodotti di qualità. Siamo qui per aiutarti a trovare esattamente ciò di cui hai bisogno. Non esitare a contattarci per qualsiasi informazione o per scoprire di più sulla nostra vasta gamma di prodotti. La tua soddisfazione è la nostra priorità.',
    },
    contact: 'Contattaci',
  },
})

export default locales
