import {
  MailFilled,
  MailOutlined,
  PhoneFilled,
  PhoneOutlined,
} from '@ant-design/icons'
import { Space, Switch } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { memo, useEffect, useState } from 'react'
import logo from '../images/logo_bianco.png'
import './../App.css'
import locales from '../locales/locale'

const Menu = ({
  setLang,
  lang,
}: {
  setLang: React.Dispatch<React.SetStateAction<string>>
  lang: string
}): JSX.Element => {
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsFixed(scrollTop > 0) // Imposta isFixed a true se lo scroll è maggiore di 0
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isFixedClassName = isFixed ? 'is-fixed' : ''

  return (
    <Header id="header" className={isFixedClassName}>
      <div className={`info-menu ${isFixedClassName}`}>
        <div style={{ float: 'right' }}>
          <Space
            style={{
              borderRight: '1px #ffffff3d solid',
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <PhoneOutlined style={{ color: 'white' }} />
            <a href="tel:+393513725799" style={{ color: 'white' }}>
              +39 3513725799
            </a>
          </Space>
          <Space
            style={{
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <MailOutlined style={{ color: 'white' }} />
            <a href="mailto:info@bermarsrls.com" style={{ color: 'white' }}>
              info@bermarsrls.com
            </a>
          </Space>
        </div>
      </div>
      <Space
        direction="horizontal"
        className="menu-items"
        // align="start"
        style={{
          padding: 8,
          color: isFixed ? 'black' : 'white',
        }}
      >
        <img src={logo} alt="Sfondo" className={`logo ${isFixedClassName}`} />
        <div className="text-logo">
          <span>ERMAR</span>
        </div>
      </Space>
      <Space className={`lingua ${isFixedClassName}`}>
        <a href="tel:+393513725799">
          <PhoneFilled />
        </a>
        <a href="mailto:info@bermarsrls.com">
          <MailFilled />
        </a>
        <Switch
          checked={lang === 'en'}
          onChange={(language) => {
            setLang(language ? 'en' : 'it')
            locales.setLanguage(language ? 'en' : 'it')
          }}
          checkedChildren="EN"
          unCheckedChildren="IT"
        />
      </Space>
    </Header>
  )
}

export default memo(Menu)
